export const formatRentRange = (rentRange: { rentMin?: number; rentMax?: number }) => {
  const { rentMin, rentMax } = rentRange
  if (!rentMin || !rentMax || !Number.isFinite(rentMin) || !Number.isFinite(rentMax)) {
    return null
  }

  return `$${Number(rentMin).toLocaleString("en")} - $${Number(rentMax).toLocaleString("en")}`
}

export const formatBedrooms = (bedrooms: number) => {
  if (bedrooms === 0) {
    return "Studio "
  } else if (bedrooms === 9) {
    return "Penthouse "
  } else {
    return `${bedrooms} Bedroom `
  }
}
