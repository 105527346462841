import React, { useState } from "react"

type SetValue = (value: any) => void

interface AppContextInterface {
  priceMin: any
  setPriceMin: SetValue
  priceMax: any
  setPriceMax: SetValue
  bedsMin: any
  setBedsMin: SetValue
  bedsMax: any
  setBedsMax: SetValue
  baths: any
  setBaths: SetValue
  amenities: any
  setAmenities: SetValue
  sort: any
  setSort: SetValue
}
const ModelContext = React.createContext<AppContextInterface | null>(null)

const ModelProvider = ({ children }) => {
  const [priceMin, setPriceMin] = useState(null)
  const [priceMax, setPriceMax] = useState(null)
  const [bedsMin, setBedsMin] = useState(null)
  const [bedsMax, setBedsMax] = useState(null)
  const [baths, setBaths] = useState(null)
  const [amenities, setAmenities] = useState([])
  const [sort, setSort] = useState(null)

  return (
    <ModelContext.Provider
      value={{
        priceMin,
        setPriceMin,
        priceMax,
        setPriceMax,
        bedsMin,
        setBedsMin,
        bedsMax,
        setBedsMax,
        baths,
        setBaths,
        amenities,
        setAmenities,
        sort,
        setSort,
      }}
    >
      {children}
    </ModelContext.Provider>
  )
}
export { ModelProvider, ModelContext }
