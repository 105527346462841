import { setCookie, getCookie } from "../utils/cookie"
import QueryString from "query-string"
import { useEffect } from "react"
const useSaveSrc = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const parsed = QueryString.parse(window.location.search)
      if (parsed["src"]) {
        const current = getCookie("leadSource")
        if (!current) {
          setCookie("leadSource", parsed["src"], 15778800000)
        }
      }
    }
  }, [])
}
/** export */
export default useSaveSrc
