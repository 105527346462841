import { getCookie } from "./cookie"

const getPhoneNumber = (community) => {
  let leadSource
  const leadSources = community.leadSources
  if (typeof window !== "undefined") {
    const leadSourceAbbreviation = getCookie("leadSource")
    if (leadSourceAbbreviation) {
      leadSource = leadSources.find((source) => source.leadSourceAbbreviation === leadSourceAbbreviation)
    }
  }
  if (leadSource == null) {
    leadSource = leadSources.find((source) => source.leadSourceAbbreviation === "nht")
  }

  if (leadSource && leadSource?.phoneNumber) {
    return leadSource.phoneNumber
  }

  // If nothing else return the default number
  return "(651) 689-4830"
}
export { getPhoneNumber }
