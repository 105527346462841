import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import useSaveSrc from "../hooks/use-save-src"
import { useState } from "react"

/** components */
import LayoutListings from "../components/LayoutListings"
import Apartments from "../components/Apartments"
import { theme } from "../components/Theme"

/** svg */
import SignInIcon from "../../assets/sign-in.svg"
import NorhartColorLogo from "../../assets/norhart-logo-apartments.svg"
import SegmentedControl from "../components/SegmentedControl"
import NorhartDarkCircleIcon from "../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const ApartmentsPage: React.FC<Props> = (props) => {
  useSaveSrc()

  const [selectedTab, setSelectedTab] = useState("communities")

  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/apartments/",
          name: "Norhart | Apartments For Rent in Forest Lake, Blaine and Oakdale MN",
          image: `${config.siteMetadata.siteUrl}/apartments/listings/norhart-apartment-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <LayoutListings
      title="Norhart | Apartments For Rent in Forest Lake, Blaine and Oakdale MN"
      description="Norhart apartments, the top apartment listing and rent guide in Minneapolis, Minnesota. Discover luxury apartments in Forest Lake, Blaine, and Oakdale, Minnesota."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/apartments/listings/norhart-apartment-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/apartments/listings/norhart-apartment-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <div className="h-100 d-flex flex-column" style={{ backgroundColor: "#f2f2ff" }}>
        <div className="m-0 p-0">
          <div className="p-0 text-center">
            <div className="text-center">
              <div className="row pt-1 pb-1 m-0">
                <div className="col-3">
                  <span className="d-none d-sm-block">
                    <a
                      href="/apartments/"
                      title="Norhart Apartments"
                      target="_blank"
                      className="alert-link"
                      style={{ color: "#0051c7", fontSize: "14px", textDecoration: "none" }}
                    >
                      Apartments
                    </a>
                  </span>
                </div>
                <div className="col-6">
                  <a href="/" title="Norhart Logo" target="_blank">
                    <NorhartColorLogo />
                  </a>
                </div>
                <div className="col-3">
                  <span className="d-none d-sm-block">
                    <SignInIcon />
                    <a
                      href="https://norhart.twa.rentmanager.com"
                      title="Norhart Account Login"
                      target="_blank"
                      className="alert-link ps-1"
                      style={{ color: "#0051c7", fontSize: "14px", textDecoration: "none" }}
                    >
                      My Account
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-block d-lg-none mt-2 mb-3 me-auto px-2">
            <SegmentedControl
              items={[
                {
                  label: "All",
                  value: "communities",
                },
                {
                  label: "Promo",
                  value: "promo",
                },
                {
                  label: "Map",
                  value: "map",
                },
              ]}
              onChangeSegment={setSelectedTab}
            />
          </div>
        </div>

        <Apartments
          communities={props.data.allCommunity.nodes}
          communityLeadSource={props.data.allCommunityLeadSource.nodes}
          selectedTab={selectedTab}
        />
      </div>
    </LayoutListings>
  )
}

export const pageQuery = graphql`
  query {
    allCommunity {
      nodes {
        id
        name
        urlPath
        primaryProperty {
          addressLine1
          city
          dateBuilt
          dateRemodeled
          latitude
          longitude
          state
          postalCode
        }
        rentRanges {
          bedrooms
          rentMin
          rentMax
        }
        featuredImage {
          url
          caption
          altText
          displayOrder
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1024, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
            }
          }
        }
        region {
          id
          urlSlug
        }
        amenities {
          name
        }
        petPolicies {
          petType
          allowed
        }
        bedroomsMax
        bedroomsMin
        floorPlans {
          amenities {
            name
          }
          bathrooms
          bedrooms
          squareFeetMax
          squareFeetMin
          rentMax
          rentMin
          promotionalMessage
        }
        leadSources {
          phoneNumber
          leadSourceAbbreviation
        }
        rentMax
        rentMin
        promotionalMessage
        websitePromotionalMessage
        allowOnlineApplications
      }
    }
    allCommunityLeadSource {
      nodes {
        communityId
        communityName
        leadSourceAbbreviation
        leadSourceName
        emailAddress
        phoneNumber
      }
    }
  }
`

/** export */
/** export */
export default ApartmentsPage
